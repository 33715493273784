export default {
    BACK: {
        key: 'BACK',
        name: 'Back'
    },
    CHEST: {
        key: 'CHEST',
        name: 'Chest'
    },
    TRICEPS: {
        key: 'TRICEPS',
        name: 'Triceps'
    },
    BICEPS: {
        key: 'BICEPS',
        name: 'Biceps'
    },
    SHOULDERS: {
        key: 'SHOULDERS',
        name: 'Shoulders'
    },
    FOREARMS: {
        key: 'FOREARMS',
        name: 'Forearms'
    },
    LEGS: {
        key: 'LEGS',
        name: 'Legs'
    },
    ABS: {
        key: 'ABS',
        name: 'Abs'
    },
};
