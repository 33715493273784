<template>
  <v-idle v-if="isIdleLogoutActive" :duration="30" @idle="onIdle" @remind="onRemind" :reminders="[20]" style="display: none;"></v-idle>
  <div v-if="showIdleReminder" class="idle-reminder">Budete brzo odhlášen. <button type="button" class="btn btn-sm btn-outline-dark" @click="hideIdleReminder">Zrušit</button></div>
  <router-view></router-view>
</template>

<script setup>
  import { computed, onMounted, ref } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import useUtils from "@/composables/useUtils";

  const store = useStore();
  const router = useRouter();
  const showIdleReminder = ref(false);

  console.log("App created.");
  console.log("isIdleLogoutActive: %s", store.getters["config/isIdleLogoutActive"]);
  console.log("user: %o", JSON.parse(localStorage.getItem('user')));

  const isIdleLogoutActive = computed(() => {
    return store.getters["config/isIdleLogoutActive"] && store.getters["auth/isLoggedIn"];
  });

  onMounted(async () => {

    if (!store.getters["config/isIdleLogoutActive"]) {

      // init store using data from local storage
      store.commit("auth/setup", {
        accessToken: localStorage.getItem('accessToken'),
        accessTokenExp: localStorage.getItem('accessTokenExp'),
        refreshToken: localStorage.getItem('refreshToken'),
        refreshTokenExp: localStorage.getItem('refreshTokenExp'),
        user: JSON.parse(localStorage.getItem('user')),
      });

      if (store.getters["auth/getAccessToken"]) {

        // get logged user
        await store.dispatch("auth/get");

        const loggedUser = store.getters["auth/getUser"];
        console.log('loggedUser: %o', loggedUser);

        if (store.getters["auth/isLoggedIn"]) {

          // create timer for refresh token
          await store.dispatch('auth/refreshToken');

          // if user is logged in -> route to home page
          await router.push({name: 'home'});
        } else {
          // if user is not logged in -> route to login page
          await router.push({name: 'login'});
        }
      }
    }
  });

  function onRemind(time) {
    console.log('on idle reminder: %s', time);
    // TODO show reminder message
    showIdleReminder.value = true;
  }

  async function onIdle() {
    console.log('on idle');
    // logout user
    await store.dispatch("auth/logout");
    // route to login page
    await router.push({ name: 'login' });
    // hide idle reminder
    showIdleReminder.value = false;
  }

  function hideIdleReminder() {
    console.log('hideIdleReminder');
    // hide idle reminder
    showIdleReminder.value = false;
  }

  const isMobile = useUtils().isMobile();
  console.log('isMobile: %o', isMobile);
</script>

<style>
.idle-reminder {
  position: fixed;
  top: 0;
  background: var(--bs-danger);
  color: #fff;
  padding: .5rem;
  border-radius: 0 0 .5rem .5rem;

  .btn-outline-dark {
    margin-left: 1rem;
    color: #fff;
    border-color: #fff;
  }
}
</style>
