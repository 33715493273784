import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        apiBaseUrl: 'http://localhost:3000',
        idleLogoutActive: false,
        idleLogoutTimeout: 120
    },
    getters,
    mutations,
};
