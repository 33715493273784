export default {
    BENCH_PRESS: {
        key: 'BENCH_PRESS',
        name: 'Bench Press'
    },
    LEG_PRESS: {
        key: 'LEG_PRESS',
        name: 'Leg Press'
    },
    PULL_UPS: {
        key: 'PULL_UPS',
        name: 'Pull-Ups'
    },
    DUMBBELL_FLYES: {
        key: 'DUMBBELL_FLYES',
        name: 'Dumbbell Flyes'
    },
    DUMBBELL_PRESS: {
        key: 'DUMBBELL_PRESS',
        name: 'Dumbbell Press'
    },
    BARBELL_CURLS: {
        key: 'BARBELL_CURLS',
        name: 'Barbell Curls'
    },
};
