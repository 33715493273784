import { createApp } from 'vue';
import App from '@/App.vue';
import router from "@/router";
import store from '@/store';
import Vuelidate from 'vuelidate';
import Vidle from 'v-idle-3';

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRightFromBracket, faGear, faSpinner, faMedal, faDumbbell, faCircleInfo, faPeoplePulling, faFaceSmileWink, faArrowRight, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import WorkoutFilters from "@/filters/WorkoutFilters";

import './assets/css/main.css';

library.add(faArrowRightFromBracket, faGear, faSpinner, faMedal, faDumbbell, faCircleInfo, faPeoplePulling, faFaceSmileWink, faArrowRight, faCircleArrowRight);

fetch(process.env.BASE_URL + "config.json?t=" + new Date().getTime())
    .then((response) => response.json())
    .then((config) => {
        console.log('app configuration: %s', JSON.stringify(config));
        window.config = config;
        store.commit('config/set', config);

        const app = createApp(App);

        app.use(bootstrap)
            .use(router)
            .use(store)
            .use(Vuelidate)
            .use(Vidle)
            .component('font-awesome-icon', FontAwesomeIcon)
            .mount("#app");

        app.config.globalProperties.$filters = {
            ...WorkoutFilters
        }
    });
