import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        accessToken: null,
        accessTokenExp: null,
        refreshToken: null,
        refreshTokenExp: null,
        user: null,
    },
    getters,
    actions,
    mutations,
};
