export default {
    isLoggedIn(state) {
        return state.isLoggedIn;
    },
    getUser(state) {
        return state.user;
    },
    getAccessToken(state) {
        return state.accessToken;
    },
    getAccessTokenExp(state) {
        return state.accessTokenExp;
    },
    getRefreshToken(state) {
        return state.refreshToken;
    },
    getRefreshTokenExp(state) {
        return state.refreshTokenExp;
    },
};
