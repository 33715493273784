import { createStore } from 'vuex';

import configModule from '@/store/modules/config';
import authModule from '@/store/modules/auth';

export default createStore({
    modules: {
        config: configModule,
        auth: authModule,
    }
});
