export default {
    reset(state) {
        // clear state
        state.isLoggedIn = false;
        state.accessToken = null;
        state.refreshToken = null;
        state.accessTokenExp = null;
        state.refreshTokenExp = null;
        state.user = null;
    },
    setup(state, payload) {
        // setup state
        if (payload) {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
            state.accessTokenExp = payload.accessTokenExp;
            state.refreshTokenExp = payload.refreshTokenExp;
            state.user = payload.user;
        }
    },
};
