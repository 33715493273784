import axios from "axios";
import store from '../store'

export default function useApi(withAuthorization = true) {

    const config = {};

    if (withAuthorization) {
        config.headers = {
            'Authorization': 'Bearer ' + store.getters["auth/getAccessToken"],
        };
    }

    function getError(e) {
        if (!e.response) {
            console.log('auth error: %o', e.message);
            return e;
        } else {
            console.log('auth error: %o', e.status);
            return e.response;
        }
    }

    const get = async (url) => {

        let result, error;

        try {
            const response = await axios.get(
                store.getters["config/getApiBaseUrl"] + url,
                config);

            result = response.data;

        } catch (e) {
            console.log('error calling GET api operation: %o', e);
            error = getError(e);
        }

        return {
            result,
            error
        }
    }

    const post = async (url, data) => {

        let result, error;

        try {
            const response = await axios.post(
                store.getters["config/getApiBaseUrl"] + url,
                data,
                config);

            result = response.data;

        } catch (e) {
            console.log('error calling POST api operation: %o', e);
            error = getError(e);
        }

        return {
            result,
            error
        }
    }

    const put = async (url, data) => {

        let result, error;

        try {
            const response = await axios.put(
                store.getters["config/getApiBaseUrl"] + url,
                data,
                config);

            result = response.data;

        } catch (e) {
            console.log('error calling PUT api operation: %o', e);
            error = getError(e);
        }

        return {
            result,
            error
        }
    }

    const remove = async (url) => {

        let result, error;

        try {
            const response = await axios.delete(
                store.getters["config/getApiBaseUrl"] + url,
                config);

            result = response.data;

        } catch (e) {
            console.log('error calling DELETE api operation: %o', e);
            error = getError(e);
        }

        return {
            result,
            error
        }
    }

    return {
        get,
        post,
        put,
        delete: remove
    };
}
