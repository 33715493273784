import * as jose from 'jose';
import router from "@/router";
import AuthService from "@/services/AuthService";

export default {
    async get(context) {
        console.log('get()');

        // call auth API
        const response = await AuthService.get();

        if (response.error) {

            // clear local storage
            if (!context.rootGetters["config/isIdleLogoutActive"]) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('accessTokenExp');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('refreshTokenExp');
                localStorage.removeItem('user');
            }

            // clear state
            context.commit('reset');

            return;
        }

        console.log('get auth response: %o', response.result);

        // set state
        context.state.isLoggedIn = true;
    },
    async login(context, payload) {
        console.log('login(payload=%s)', JSON.stringify(payload));

        // call auth API
        const response = await AuthService.login({
            membership_pin: payload.pin,
        });

        if (response.error) {
            // clear state
            context.commit('reset');

            return;
        }

        console.log('auth response: %o', response.result);

        // decode access token
        const accessTokenPayload = jose.decodeJwt(response.result.access_token);
        console.log('accessTokenPayload: %o', accessTokenPayload);

        // decode refresh token
        const refreshTokenPayload = jose.decodeJwt(response.result.refresh_token);
        console.log('refreshTokenPayload: %o', refreshTokenPayload);

        // setup state
        context.commit("setup", {
            accessToken: response.result.access_token,
            accessTokenExp: accessTokenPayload.exp,
            refreshToken: response.result.refresh_token,
            refreshTokenExp: refreshTokenPayload.exp,
            user: {
                email: accessTokenPayload.email,
                membershipPin: accessTokenPayload.membership_pin,
                name: accessTokenPayload.name,
                nickname: accessTokenPayload.nickname,
                phone: accessTokenPayload.phone,
                referrer: accessTokenPayload.referrer,
                stripeCustomerId: accessTokenPayload.stripe_customer_id,
            },
        });

        context.state.isLoggedIn = true;

        console.log('accessTokenExp: %s', context.state.accessTokenExp);
        console.log('refreshTokenExp: %s', context.state.refreshTokenExp);
        console.log('user: %o', context.state.user);

        if (!context.rootGetters["config/isIdleLogoutActive"]) {

            // store user and tokens in local storage
            localStorage.setItem('accessToken', context.state.accessToken);
            localStorage.setItem('accessTokenExp', context.state.accessTokenExp);
            localStorage.setItem('refreshToken', context.state.refreshToken);
            localStorage.setItem('refreshTokenExp', context.state.refreshTokenExp);
            localStorage.setItem('user', JSON.stringify(context.state.user));
        }

        // create timer for refresh token
        await context.dispatch('refreshToken');

    },
    async logout(context) {

        // call auth API
        await AuthService.logout();

        // clear state
        context.commit('reset');

        // clear local storage
        if (!context.rootGetters["config/isIdleLogoutActive"]) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('accessTokenExp');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshTokenExp');
            localStorage.removeItem('user');
        }
    },
    async refreshToken(context) {

        if (!context.state.accessTokenExp) {
            return;
        }

        const accessTokenExpDate = new Date(context.state.accessTokenExp * 1000);
        const accessTokenDurationToRefresh = accessTokenExpDate.getTime() - new Date().getTime() - 1000 * 60;
        console.log('accessTokenDurationToRefresh: %s', accessTokenDurationToRefresh);

        setTimeout(async () => {
            console.log('refresh access token');

            if (!context.state.refreshToken) {
                return;
            }


            // call auth API
            const response = await AuthService.refresh();

            if (response.error) {

                // clear local storage
                if (!context.rootGetters["config/isIdleLogoutActive"]) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('accessTokenExp');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('refreshTokenExp');
                    localStorage.removeItem('user');
                }

                // clear state
                context.commit('reset');

                // if user is not logged in -> route to login page
                await router.push({ name: 'login' });

                return;
            }


            /*const response = await axios.put(
                context.rootGetters["config/getApiBaseUrl"] + '/auth',
                {},
                {
                    headers: {
                        'Authorization': 'Bearer ' + context.state.refreshToken,
                    }
                });*/

            console.log('refresh auth response: %o', response.result);

            // decode access token
            const accessTokenPayload = jose.decodeJwt(response.result.access_token);
            console.log('accessTokenPayload: %o', accessTokenPayload);

            // decode refresh token
            const refreshTokenPayload = jose.decodeJwt(response.result.refresh_token);
            console.log('refreshTokenPayload: %o', refreshTokenPayload);

            context.state.accessToken = response.result.access_token;
            context.state.accessTokenExp = accessTokenPayload.exp;

            context.state.refreshToken = response.result.refresh_token;
            context.state.refreshTokenExp = refreshTokenPayload.exp;

            console.log('accessTokenExp: %s', context.state.accessTokenExp);
            console.log('refreshTokenExp: %s', context.state.refreshTokenExp);

            if (!context.rootGetters["config/isIdleLogoutActive"]) {

                // store user and tokens in local storage
                localStorage.setItem('accessToken', context.state.accessToken);
                localStorage.setItem('accessTokenExp', context.state.accessTokenExp);
                localStorage.setItem('refreshToken', context.state.refreshToken);
                localStorage.setItem('refreshTokenExp', context.state.refreshTokenExp);
            }

            // create timer for refresh token
            await context.dispatch('refreshToken');

        }, accessTokenDurationToRefresh);
    },
    async setNickname(context, nickname) {
        console.log('setNickname(nickname=%s)', nickname);

        context.state.user.nickname = nickname;
        console.log('user: %o', context.state.user);

        if (!context.rootGetters["config/isIdleLogoutActive"]) {

            // store user in local storage
            localStorage.setItem('user', JSON.stringify(context.state.user));
        }
    },
};
