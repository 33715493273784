export default {
    EASY: {
        key: 'EASY',
        name: 'Easy'
    },
    HARD: {
        key: 'HARD',
        name: 'Hard'
    },
};
