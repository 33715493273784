import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("@/views/HomePage.vue"),
        },
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/LoginPage.vue"),
        },
        {
            path: "/setup",
            name: "setup",
            component: () => import("@/views/SetupPage.vue"),
        },
        {
            path: "/settings",
            name: "settings",
            component: () => import("@/views/SettingsPage.vue"),
        },
        {
            path: "/workout-log",
            name: "workout-log",
            component: () => import("@/views/WorkoutLogPage.vue"),
        },
        /*{
            path: "/product/:id",
            name: "product",
            component: ProductView,
            children: [
                {
                    // complete path: /product/:id/comments
                    path: "comments",
                    component: ProductCommentsView,
                },
            ],
        },*/
        {
            path: "/:catch(.*)",
            component: () => import("@/views/HomePage.vue"),
        }
    ],
});

router.beforeEach(async (to) => {
    console.log('router.beforeEach: %s', JSON.stringify(to));
    // verifying if the user is not authenticated
    const isAuthenticated = store.getters["auth/isLoggedIn"];
    if (!isAuthenticated && to.name !== "login") {
        return {
            name: "login",
        };
    }
});

export default router;

