import useApi from "@/composables/useApi";

const resource = "/auth/";

export default {

    async get() {
        return useApi().get(resource);
    },

    async login(data) {
        return useApi(false).post(resource, data);
    },

    async logout() {
        return useApi().delete(resource);
    },

    async refresh() {
        return useApi().put(resource);
    },
}
