import WorkoutDifficulty from "@/enums/WorkoutDifficulty";
import WorkoutSet from "@/enums/WorkoutSet";
import WorkoutActivity from "@/enums/WorkoutActivity";

export default {
    setName(key) {
        return WorkoutSet[key].name;
    },
    activityName(key) {
        return WorkoutActivity[key].name;
    },
    difficultyName(key) {
        return WorkoutDifficulty[key].name;
    }
}
