import { computed } from "vue";
import * as dayjs from "dayjs";

export default function useUtils() {

    const isMobile = () => {
        return computed(() => {
            return window.screen.width <= 760;
        });
    }

    const formatDate = (date) => {
        return dayjs(date).locale('cs').format('D. MMMM YYYY');
    }

    const formatDateTime = (date) => {
        return dayjs(date).locale('cs').format('D. MMMM YYYY HH:mm');
    }

    return {
        isMobile,
        formatDate,
        formatDateTime
    }
}
