export default {
    getApiBaseUrl(state) {
        return state.apiBaseUrl;
    },
    isIdleLogoutActive(state) {
        return state.idleLogoutActive;
    },
    getIdleLogoutTimeout(state) {
        return state.idleLogoutTimeout;
    }
};
